import axios from "axios";
import { toast } from "react-toastify";
import config from '../config';

// axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.baseURL = config.apiUrl;

axios.interceptors.response.use(null, (error) => {
  const expectedError =
    error?.response?.status >= 400 && error?.response?.status <= 500;

  if (expectedError) {
    toast.error(error?.message);
  }

  return Promise.reject(error?.message);
});

const method = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
};

export default method;
